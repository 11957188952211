angular.module('llax')
    .controller('PublicationOverviewController', function($modal, $q, $rootScope, $scope, $timeout, $translate, growl, uiGridConstants,
                    ChannelService, CommunicationChannelResource, ContactsResource, InfiniteScrollHelper, JobResource, PublicationResource, QueryPublicationResource, SelectionResource, PublicationMode, RepublishResource) {

        var PUBLICATION_GRID_STATES_KEY = "publication-grid-states";
        var DEFAULT_GRID_STATE_KEY = "default-state";
        var JOBS_GRID_STATE_KEY = "jobs-state";

        $scope.publicationsGridApi = null;

        $scope.refreshPage = function () {
            window.location.reload();
        };

        function getCounterTemplate(field, key) {
            return '<div class="ui-grid-cell-contents text-center">' +
            '  <span>{{row.entity.' +field+ ' || 0}}</span>' +
            '</div>';
        }

        function getWithStatusTemplate(field, key) {
            return '<div class="ui-grid-cell-contents text-center">' +
            '  <span data-ng-if="(row.entity.' + field +' || 0) == 0">\n' +
            '    {{row.entity.' +field+ ' || 0}}\n' +
            '  </span>  ' +
            '  <a data-ng-if="row.entity.'+ field +'|| 0 > 0" data-download data-ng-href="/browse?publicationTaskId={{row.entity.id}}&finalStatus=' + key + '" target="_self">\n' +
            '    {{row.entity.'+ field +'}}\n' +
            '  </a>' +
            '</div>';
        }

        function getWithoutStatusTemplate(field) {
            return '<div class="ui-grid-cell-contents text-center">' +
            '  <span data-ng-if="(row.entity.' + field +' || 0) == 0">\n' +
            '    {{row.entity.' +field+ ' || 0}}\n' +
            '  </span>  ' +
            '  <a data-ng-if="row.entity.'+ field +'|| 0 > 0" data-download data-ng-href="/browse?publicationTaskId={{row.entity.id}}" target="_self">\n' +
            '    {{row.entity.'+ field +'}}\n' +
            '  </a>' +
            '</div>';
        }

        var columnDefs = [{
            field: 'totalItemsCount',
            displayName: $translate.instant('PUBLICATIONS.ITEMS'),
            headerTooltip: true,
            cellTemplate: '<div class="ui-grid-cell-contents" ng-class="{\'cell-status-running\':row.entity.taskStatus == \'RUNNING\'}">\n' +
                '  <span>{{row.entity.statusCount.itemCounts.SUCCESS || 0}}/{{row.entity.statusCount.itemCounts.VALID || 0}}</span>\n' +
                '  <a ng-if="row.entity.taskStatus == \'RUNNING\'" class="progress-bar-container" href="#" tooltip-append-to-body="true" data-tooltip="{{row.entity.statusCount.itemCounts.SUCCESS || 0}} of {{row.entity.statusCount.itemCounts.VALID || 0}} completed.">\n' +
                '    <progressbar-custom type="dynamic" value="row.entity.statusCount.itemCounts.SUCCESS || 0" max="row.entity.statusCount.itemCounts.VALID || 0"></progressbar-custom>\n' +
                '    </a>\n' +
                '</div>',
            cellClass: 'text-center',
            width: 120
        }, {
            field: 'taskStatus',
            displayName: $translate.instant('PUBLICATIONS.STATUS'),
            headerTooltip: true,
            cellClass: 'text-center',
            cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                    '<span class="label {{row.entity.taskStatus == \'ERROR\' ? \'publication-response-error\' : \'\'}}" data-ng-click="row.entity.taskStatus == \'ERROR\' && grid.appScope.showMessages(row.entity)" data-ng-class="grid.appScope.getStatusLabel(COL_FIELD)" tooltip-placement="top" tooltip-append-to-body="true" tooltip-html-unsafe="{{row.entity.message}}" tooltip-trigger="mouseenter">\n' +
                    '    {{COL_FIELD}}\n' +
                    '</span>\n' +
                '</div>',
            width: 100
        }, {
            field: 'destinationId',
            displayName: $translate.instant('PUBLICATIONS.DESTINATION'),
            headerTooltip: true,
            cellTooltip: true,
            cellTemplate: '<div data-ng-if="!row.entity.subDestination" class="ui-grid-cell-contents">\n' +
                '    <span>\n' +
                '      <i class="syncons" data-ng-class="grid.appScope.getDestinationIcon(row.entity)"></i>\n' +
                '    </span>\n' +
                '    {{grid.appScope.getDestinations(row.entity)}}\n' +
                '</div>' +
                '<div data-ng-if="row.entity.subDestination" class="ui-grid-cell-contents">\n' +
                '    <span>\n' +
                '      <i class="syncons syncons-double-arrows-right"></i>\n' +
                '    </span>\n' +
                '    {{grid.appScope.getDescriptions(row.entity)}} ({{grid.appScope.getDestinations(row.entity)}})\n' +
                '</div>',
            width: 220,
            minWidth: 180
        }, {
            field: 'successfulItems',
            displayName: $translate.instant('PUBLICATIONS.ITEMS.SUCCESS'),
            headerTooltip: true,
            cellTemplate: getWithStatusTemplate('statusCount.itemCounts.SUCCESS','SUCCESS'),
            cellTooltip: true,
            width: 80
        }, {
            field: 'deliveredItems',
            displayName: $translate.instant('PUBLICATIONS.ITEMS.DELIVERED'),
            headerTooltip: true,
            cellTemplate: getCounterTemplate('statusCount.itemCounts.DELIVERED'),
            cellTooltip: true,
            width: 80
        }, {
            field: 'unchangedItems',
            displayName: $translate.instant('PUBLICATIONS.ITEMS.UNCHANGED'),
            headerTooltip: true,
            cellTemplate: getWithStatusTemplate('statusCount.itemCounts.UNCHANGED','UNCHANGED'),
            cellTooltip: true,
            width: 80
        }, {
            field: 'failedItems',
            displayName: $translate.instant('PUBLICATIONS.ITEMS.FAILED'),
            headerTooltip: true,
            cellTemplate: getWithStatusTemplate('statusCount.itemCounts.ERROR','ERROR'),
            cellTooltip: true,
            width: 80
        }, {
            field: 'invalidItems',
            displayName: $translate.instant('PUBLICATIONS.ITEMS.INVALID'),
            headerTooltip: true,
            cellTemplate: getCounterTemplate('statusCount.itemCounts.INVALID','INVALID'),
            cellTooltip: true,
            width: 80
        }, {
            field: 'skippedItems',
            displayName: $translate.instant('PUBLICATIONS.ITEMS.SKIPPED'),
            headerTooltip: true,
            cellTemplate: getCounterTemplate('statusCount.itemCounts.SKIPPED','SKIPPED'),
            cellTooltip: true,
            visible: false, // initially not visible
            width: 80
        }, {
            field: 'creationDate',
            displayName: $translate.instant('PUBLICATIONS.START'),
            headerTooltip: true,
            cellFilter: "date:'medium'",
            width: 180
        }, {
            field: 'finishedDate',
            displayName: $translate.instant('PUBLICATIONS.END'),
            headerTooltip: true,
            cellFilter: "date:'medium'",
            width: 180
        }, {
            field: 'user',
            displayName: $translate.instant('PUBLICATIONS.USER'),
            headerTooltip: true,
            cellTooltip: true,
            width: 160
        },{
            field: 'name',
            displayName: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.name'),
            headerTooltip: true,
            cellTooltip: true,
            cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                '  {{::grid.appScope.getJobName(row.entity)}}\n' +
                '</div>',
            width: 120
        }, {
            field: 'publicationType',
            displayName: $translate.instant('PUBLICATIONS.PUBLICATION_TYPE'),
            headerTooltip: true,
            cellTooltip: true,
            cellTemplate: '<div class="ui-grid-cell-contents text-left">\n' +
                '  <span data-translate>{{\'PUBLICATIONS.PUBLICATION_TYPE.\' + row.entity.publicationType}}</span>\n' +
                '</div>',
            width: '*',
            minWidth: 120
        }];

        columnDefs.push({
            displayName: $translate.instant('ACTIONS'),
            headerTooltip: true,
            name: 'actions',
            enableCellEdit: false,
            enableCellSelection: false,
            allowCellFocus: false,
            pinnedRight: true,
            enableColumnResizing: false,
            enableSorting: false,
            enableHiding: false,
            resizable: false,
            headerCellClass: 'text-center',
            cellClass: 'text-right',
            cellTemplate:
                '<div class="ui-grid-cell-contents">\n' +
                    '<div class="published-items-actions-div actions">\n' +
                        '<button class="btn btn-cell action-btn-slot" ' +
                                'data-ng-disabled="row.entity.finalStatus == \'ABORTED\'" ' +
                                'data-ng-if="!(row.entity.finished || row.entity.finishing) && grid.appScope.isAllowed(row.entity, false)" ' +
                                'data-ng-click="grid.appScope.stopPublication(row.entity)" ' +
                                'title="{{\'PUBLICATIONS.ABORT\' | translate }}"' +
                                '>\n' +
                            '<i class="glyphicon glyphicon-stop"></i>\n' +
                        '</button>\n' +
                        '<div class="actions" data-ng-if="row.entity.publishedItemsCount > 0 && row.entity.taskStatus != \'RUNNING\'">\n' +
                            '<button class="btn btn-cell action-btn-slot" ' +
                                    'data-ng-if="grid.appScope.isAllowed(row.entity, true)" ' +
                                    'data-ng-click="grid.appScope.reRunPublication(row.entity)" ' +
                                    'title="{{\'PUBLICATIONS.RERUN\' | translate }}"' +
                                    '>\n' +
                                '<i class="glyphicon glyphicon-refresh"></i>\n' +
                            '</button>\n' +
                            '<a class="action" href="/browse?publicationTaskId={{row.entity.id}}" title="{{\'PUBLICATIONS.BROWSE_IN_ITEMS_LIST\' | translate }}">' +
                                '<i class="glyphicon glyphicon-link"></i>' +
                            '</a>\n' +
                            '<a class="action" ' +
                               'href="/catalog?publicationTaskId={{row.entity.id}}&withPublicationTaskChecksums=true" ' +
                               'title="{{\'PUBLICATIONS.BROWSE_IN_ONLINE_CATALOG\' | translate }}">\n' +
                                '<i class="syncons syncons-catalog"></i>\n' +
                            '</a>\n' +
                        '</div>\n' +
                    '</div>\n' +
                '</div>',
            width: 120,
        });

        $scope.gridOptions = {
            data: 'publications',
            columnDefs: columnDefs,
            enableColumnMenus: false,
            enableGridMenu: true,
            enableSorting: false,
            rowHeight: 34,
            onRegisterApi: function(gridApi) {
                $scope.publicationsGridApi = gridApi;
            }
        };

        $scope.getJobName = function(entity) {
            var name = "";
            var job = _.find($scope.jobs, {id:entity.jobId});
            if (job) {
                name = job.name;
            }
            return name;
        };

        // Default query parameters used in 'scrollHelper.load'
        var DEFAULT_QUERY_PARAMS = {
            limit: 20
        };

        // Accessor to get/set publications, used in 'InfiniteScrollHelper'
        $scope.publications = [];
        function publicationsAccessor(publications) {
            if (!_.isUndefined(publications)) {
                // FIXME: Enrich with publication type and destination information (channel or organization name and subDestination, if available)
                $scope.publications = publications;
            }
            return $scope.publications;
        }

        $scope.stopPublication = function(entity) {
            PublicationResource.stopPublication({
                    taskId: entity.id
                },
                function(response) {
                    // Handle success response here
                },
                function(response) {
                    // Handle error response here
                }
            );
        };

        $scope.reRunPublication = function(entity) {
            RepublishResource.save({
                    taskId: entity.id
                },
                function(response) {
                    growl.success("COMMUNICATION_CHANNELS.REPUBLICATION_STARTED_SUCCESSFULLY");
                    $scope.scrollHelper.load({ queryParams: DEFAULT_QUERY_PARAMS });
                },
                function(response) {
                    growl.error($rootScope.getErrorMessage(response, 'COMMUNICATION_CHANNELS.ERROR_STARTING_REPUBLICATION'));
                }
            );
        };

        // Error handler used in 'InfiniteScrollHelper'
        function errorHandler(errorStatus, errorData) {
            $scope.status = errorStatus;
        }

        // The 'infinite scroll' helper object
        // Simple set the 'resource' object, scope and data and gridApi variable, and call 'addToGridOptions' to enable 'infinite scrolling'
        $scope.scrollHelper = new InfiniteScrollHelper(QueryPublicationResource, $scope, publicationsAccessor, 'publicationsGridApi', errorHandler);
        $scope.scrollHelper.addToGridOptions($scope.gridOptions, function(gridApi) {
            $scope.publicationsGridApi = gridApi;
            $rootScope.initGridState($scope, $scope.publicationsGridApi, PUBLICATION_GRID_STATES_KEY, DEFAULT_GRID_STATE_KEY, columnDefs);
        });

        $scope.responseErrors = {};
        $scope.showMessages = function(row) {
            function PublicationErrorMessagesCtrl($rootScope,$modalInstance, $scope,messages) {
                $scope.errorMessages = messages;
                $scope.close = function() {
                    $modalInstance.close($scope.channel);
                };
            }
            if ($scope.responseErrors[row.id]) {
                openErrorsModal($scope.responseErrors[row.id]);
            }
            else {
                PublicationResource.getResponseErrors({'taskId':row.id},
                    function(result) {
                        if (result && result.length > 0) {
                            $scope.responseErrors[row.id] = result;
                            openErrorsModal(result);
                        }
                    }
                );
            }
            function openErrorsModal(errors) {
                $modal.open({
                    templateUrl: 'tpl/publication-error-messages.tpl.html',
                    controller: PublicationErrorMessagesCtrl,
                    backdrop: true,
                    windowClass: 'publish-modal',
                    resolve: {
                        messages: function() {
                            return errors;
                        }
                    }
                });
            }
        };

        $scope.isAllowed = function(entity, isVerificationRequired) {
            var runJob = false;
            if (!_.isNil($rootScope.organization) && $rootScope.organization.verified) {
                runJob = true;
            } else {
                var destinationChannelIds = _.get(entity, 'destinationChannelIds');
                if (!_.isEmpty(destinationChannelIds)) {
                    runJob = !_.find(destinationChannelIds, function(channelId) {
                        var channel = _.find($scope.communicationChannels, { 'id': channelId, 'needsVerification': true});
                        return !_.isNil(channel);
                    });
                }
            }
            return ($rootScope.user.roles.indexOf('admin') > -1 || _.isEmpty(entity.user) ||
                    entity.user == $rootScope.user.userId) && (isVerificationRequired ? runJob: true);
        };

        function loadScheduleJobs() {
            return JobResource.query({
                type: 'PUBLICATION'
            }, function(response) {
                $scope.jobs = response;
            });
        }

        var jobsColumnDefs = [{
            field: 'name',
            displayName: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.name'),
            headerTooltip: true,
            width: 120
        }, {
            field: 'publicationType',
            displayName: $translate.instant('PUBLICATIONS.PUBLICATION_TYPE'),
            headerTooltip: true,
            cellTemplate: '<div class="ui-grid-cell-contents text-left">\n' +
                '  <span data-translate>{{\'PUBLICATIONS.PUBLICATION_TYPE.\' + row.entity.publicationType}}</span>\n' +
                '</div>',
            sort: {
                direction: uiGridConstants.ASC,
                priority: 0
            },
            suppressRemoveSort: true,
            width: 120
        }, {
            field: 'schedule.status',
            displayName: $translate.instant('PUBLICATIONS.STATUS'),
            headerTooltip: true,
            cellClass: 'text-center',
            cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                '  <span class="label" data-ng-class="grid.appScope.getStatusLabel(COL_FIELD)" tooltip-placement="top" tooltip-append-to-body="true" tooltip-html-unsafe="{{row.entity.message}}" tooltip-trigger="mouseenter">{{COL_FIELD}}</span>\n' +
                '</div>',
            width: 100
        }, {
            field: 'user',
            displayName: $translate.instant('PUBLICATIONS.USER'),
            headerTooltip: true,
            width: 160
        }, {
            field: 'destinations',
            displayName: $translate.instant('PUBLICATIONS.DESTINATION'),
            headerTooltip: true,
            enableSorting: false,
            cellTemplate: '<div class="ui-grid-cell-contents">\n' +
                '  {{::grid.appScope.getDestinations(row.entity)}}\n' +
                '</div>',
            width: '*',
            minWidth: 180
        }, {
            field: 'schedule.nextRunDateTime',
            displayName: $translate.instant('ITEM_LIBRARY_PUBLISH.attribute.nextRunDateTime'),
            headerTooltip: true,
            cellFilter: "date:'medium'",
            sort: {
                direction: uiGridConstants.ASC,
                priority: 1
            },
            width: 180
        }];
        jobsColumnDefs.push({
            displayName: $translate.instant('ACTIONS'),
            headerTooltip: true,
            name: 'actions',
            headerCellClass: 'text-right',
            cellClass: 'text-right',
            enableColumnResizing: false,
            enableSorting: false,
            pinnedRight: true,
            cellTemplate:
                '<div class="ui-grid-cell-contents">\n' +
                '  <div id="row-{{grid.renderContainers.body.visibleRowCache.indexOf(row)}}-actions" data-ng-if="grid.appScope.isAllowed(row.entity, false)" class="actions">\n' +
                '    <button type="button" class="btn btn-cell btn-sm action-edit action-btn-slot" title="{{\'JOB_SCHEDULE.EDIT_JOB\' | translate}}" data-ng-click="grid.appScope.editJob(row.entity)">\n' +
                '      <i class="syncons syncons-edit"></i>\n' +
                '    </button>\n' +
                '    <button type="button" class="btn btn-cell btn-sm action-run action-btn-slot" title="{{\'JOB_SCHEDULE.RUN_JOB\' | translate}}" data-ng-if="grid.appScope.isAllowed(row.entity, true)" data-ng-click="grid.appScope.runJob(row.entity)">\n' +
                '      <i class="glyphicon glyphicon-play"></i>\n' +
                '    </button>\n' +
                '    <button type="button" class="btn btn-cell btn-sm action-delete action-btn-slot" title="{{\'JOB_SCHEDULE.DELETE_JOB\' | translate}}" data-ng-click="grid.appScope.deleteJob(row.entity)">\n' +
                '      <i class="syncons syncons-delete"></i>\n' +
                '    </button>\n' +
                '  </div>\n' +
                '</div>',
            width: 120
        });

        $scope.jobsGridOptions = {
            data: 'jobs',
            columnDefs: jobsColumnDefs,
            enableColumnMenus: false,
            enableGridMenu: true,
            enableHorizontalScrollbar: uiGridConstants.scrollbars.NEVER,
            enableSorting: true,
            suppressMultiSort: true,
            rowHeight: 34,
            onRegisterApi: function(gridApi) {

                $scope.jobsGridApi = gridApi;
                $scope.jobsGridApi.core.on.rowsRendered( $scope, function(data) {
                    var gridHeight = ($scope.jobsGridOptions.rowHeight * $scope.jobs.length) + 32;
                    if (gridHeight > $scope.jobsGridMaxHeight) {
                        $scope.jobsGridOptions.enableVerticalScrollbar = uiGridConstants.scrollbars.ALWAYS;
                    } else {
                        $scope.jobsGridOptions.enableVerticalScrollbar = uiGridConstants.scrollbars.NEVER;
                    }
                });

                $rootScope.initGridState($scope, $scope.jobsGridApi, PUBLICATION_GRID_STATES_KEY, JOBS_GRID_STATE_KEY, jobsColumnDefs);

            }
        };

        $scope.editJob = function(entity) {
                SelectionResource.get({
                    selectionId: entity.selectionId
                }, function(response) {
                  if (!_.isEmpty(response)) {
                    var itemSelection = response;
                    var modalInstance = $modal.open({
                        templateUrl: 'tpl/publish-to-recipients-modal.tpl.html',
                        controller: 'PublishItemsController',
                        backdrop: true,
                        windowClass: 'publish-modal',
                        resolve: {
                            itemSelection: function() {
                                return itemSelection;
                            },
                            mode: function() {
                                return PublicationMode.PUBLISH;
                            },
                            publicationData: function() {
                                return { job : entity };
                            }
                        }
                    });
                }
            });
        };

        $scope.runJob = function(entity) {
            JobResource.run({
                jobId: entity.id
            }, function(response) {
                growl.success("JOB_SCHEDULE.PUBLICATION_STARTED_SUCCESSFULLY");
                loadScheduleJobs();
            }, function(response) {
                growl.error($rootScope.getErrorMessage(response, 'JOB_SCHEDULE.PUBLICATION_STARTED_FAILED'));
                loadScheduleJobs();
            });
        };

        $scope.deleteJob = function(entity) {
            JobResource.delete({
                jobId: entity.id
            }, function(response) {
                growl.success("JOB_SCHEDULE.JOB_DELETE_SUCCESS");
                loadScheduleJobs();
            });
        };

        $scope.getDestinationIcon = function(entity) {
            if (entity.destinationType === 'COMMUNICATION_CHANNEL') {
                return 'syncons-channels';
            } else if (entity.directPublication) {
                return 'syncons-add-user';
            } else {
                return 'syncons-contacts';
            }
        };

        $scope.getDestinations = function(entity) {

            // NOTE:
            // This method should best be split into 2:
            // One for the "jobs", where an "entity" can have multiple destinations,
            // and for the "publications" where an "entity" can only have ONE single destination!
            // This here is complete crap!

            var destinations = [];
            var channelSubDestinations = entity.channelSubDestinations || [];
            var channelIds = entity.destinationChannelIds || [];
            var organizationsIds = entity.destinationOrganizationIds || [];
            var destinationId = entity.destinationId || [];
            var sources = $scope.organizations.concat($scope.communicationChannels);
            var ids = channelIds.concat(organizationsIds).concat(destinationId);

            if (entity.subDestination) {
                destinations.push(entity.subDestination);
            } else {
                _.forEach(channelSubDestinations, function(subDestination) {
                    var name = subDestination.split(':')[1];
                    destinations.push(name);
                });
                _.forEach(ids, function(id) {
                    var element = _.find(sources, function(source) {
                        return source.id == id || source.organizationId == id;
                    });
                    if (element) {
                        destinations.push(element.name);
                    }
                });
            }

            return destinations.join(', ');
        };

        $scope.getDescriptions = function(entity) {
            sourceId = entity.destinationId;
            sourceCommunicationChannel = _.map($scope.communicationChannels, function(channel) {
                if (channel.id == sourceId) return channel;
            });
            sourceCommunicationChannel = _.without(sourceCommunicationChannel, undefined)[0];

            if (sourceCommunicationChannel && sourceCommunicationChannel.subDestinations) {
                return sourceCommunicationChannel.subDestinations[entity.subDestination];
            }
            return "";
        };

        $scope.toggleActive = function(job) {
            var newJob = angular.copy(job);
            newJob.type = 'PUBLICATION';
            newJob.schedule.active = !newJob.schedule.active;
            JobResource.save(newJob, function(response) {
                angular.extend(job, response);
            });
        };

        $scope.$on('channelMessageReceived', function(event, eventData) {
            var typeOfChange = '';
            if (eventData.event === ChannelService.PUBLICATION_TASK_CHANGED_EVENT) {
                typeOfChange = eventData.data.typeOfChange;
                var publication = eventData.data.publication;
                if (typeOfChange === "created") {
                    $scope.publications.unshift(publication);
                } else if (typeOfChange === "updated") {
                    var foundPublication = _.find($scope.publications, { 'id': publication.id });
                    if (!_.isNil(foundPublication)) {
                        _.merge(foundPublication, publication);
//                        publicationTask.taskStatus = publication.taskStatus;
//                        publicationTask.publishedItemsCount = publication.publishedItemsCount;
//                        publicationTask.totalItemsCount = eventData.data.publicationTotalItemsCount;
//                        publicationTask.finishedDate = eventData.data.publishedAt;
//                        publicationTask.finalStatus = eventData.data.publicationFinalStatus;
//                        publicationTask.finished = eventData.data.publicationFinished;
//                        publicationTask.finishing = eventData.data.publicationFinishing;
                    }
                }
                $scope.publicationsGridApi.core.notifyDataChange(uiGridConstants.dataChange.ALL);
            } else if (eventData.event === ChannelService.PUBLICATION_JOB_CHANGED_EVENT) {
                typeOfChange = eventData.data.typeOfChange;
                var publicationJob = eventData.data.job;

                if (typeOfChange === 'updated') {
                    var foundJob = _.find($scope.jobs, { 'id': publicationJob.id });
                    if (!_.isNil(foundJob)) {
                        _.merge(foundJob, publicationJob);
                    }
                }
            }
        });

        function loadCommunicationChannels() {
            return CommunicationChannelResource.query(
                function(communicationChannelsResponse) {
                    $scope.communicationChannels = communicationChannelsResponse;
                }
            );
        }

        function loadOrganizations() {
            return ContactsResource.query({},
                function(organizationsResponse) {
                    $scope.organizations = organizationsResponse;
                }
            );
        }

        function loadDependencies() {
            $q.all([
                loadOrganizations().$promise,
                loadCommunicationChannels().$promise,
                $scope.scrollHelper.load({ queryParams: DEFAULT_QUERY_PARAMS }).$promise,
                loadScheduleJobs().$promise
            ]).then(function(data) {
                $scope.isLoading = false;
            });
        }

        (function init() {
            $scope.isLoading = true;
            $scope.jobsGridMaxHeight = 200;
            $scope.jobs = [];
            if ($rootScope.isDataModelLoaded) {
                loadDependencies();
            } else {
                $scope.$on('dataModelLoaded', function() {
                    loadDependencies();
                });
            }
        })();

    });
